import axios from '@/plugins/axios'

export default {

  async list (data) {
    const param = new URLSearchParams(data).toString()
    return await axios.get(`receipts-signatures?${param}`)
  },

  async get (id) {
    return await axios.get(`receipts-signatures/${id}`)
  },

  async insert (data) {
    return await axios.post('receipts-signatures', data)
  },

  async update (id, data) {
    return await axios.put(`receipts-signatures/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`receipts-signatures/${id}`)
  },

}
