<template>
  <v-dialog
    v-model="show"
    max-width="700px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Assinaturas Recibos
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="mt-4">
        <v-row>
          <v-col>
            <v-btn
              color="success"
              class="mr-2"
              dark
              small
              @click="openDialog()"
            >
              <v-icon
                left
                v-text="'mdi-plus'"
              />
              Adicionar
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-sheet
              elevation="0"
              outlined
            >
              <v-simple-table
                dense
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        #
                      </th>
                      <th class="text-left">
                        Data Início
                      </th>
                      <th class="text-left">
                        Data Fim
                      </th>
                      <th class="text-left">
                        Nome
                      </th>
                      <th class="text-left">
                        Assinatura
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="receiptsSignatures.length <=0 "
                    >
                      <td
                        colspan="6"
                        class="title"
                      >
                        <i>Nenhuma assinatura encontrada</i>
                      </td>
                    </tr>
                    <tr
                      v-for="(signature, index) in receiptsSignatures"
                      :key="index"
                    >
                      <td>{{ signature.id }}</td>
                      <td>{{ signature.initialDate | date }}</td>
                      <td>{{ signature.finalDate | date }}</td>
                      <td>{{ signature.name }}</td>
                      <td>
                        <v-img
                          max-width="50px"
                          :src="signature.signature"
                        />
                      </td>
                      <td class="text-right">
                        <v-btn
                          icon
                          small
                          @click="openDialog(signature)"
                        >
                          <v-icon
                            v-text="'mdi-pencil'"
                            small
                          />
                        </v-btn>
                        <v-btn
                          icon
                          small
                          :loading="loadingDelete === signature.id"
                          @click="deleteReceiptSignature(signature)"
                        >
                          <v-icon
                            v-text="'mdi-delete'"
                            small
                          />
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <dialog-receipts-signatures-fields
      v-model="showDialogReceiptSignature"
      :receipt-signature="editReceiptSignature"
      @save="load"
    />

  </v-dialog>
</template>

<script>
  import receiptsSignaturesApi from '@/api/receipts-signatures'

  export default {

    components: {
      DialogReceiptsSignaturesFields: () => import('@/components/dialog/receipts/receipts-signatures/DialogReceiptsSignaturesFields'),
    },

    props: {
      value: {
        type: Boolean,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        loadingDelete: null,
        showDialogReceiptSignature: false,
        editReceiptSignature: null,
        receiptsSignatures: [],
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
    },

    methods: {
      async load () {
        try {
          this.loading = true

          const responseReceiptsSignatures = await receiptsSignaturesApi.list()

          this.receiptsSignatures = responseReceiptsSignatures.data.receiptsSignatures
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (signature = null) {
        if (signature) {
          signature.signatureBase64 = signature.signature
          signature.signature = null
        }

        this.editReceiptSignature = signature
        this.showDialogReceiptSignature = true
      },

      async deleteReceiptSignature (signature) {
        try {
          this.loadingDelete = signature.id

          await receiptsSignaturesApi.delete(signature.id)

          this.load()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = null
        }
      },

    },

  }
</script>
